.Join {
	display: flex;
	padding: 0 2rem;
	gap: 10rem;
}
.left-j {
	font-size: 3rem;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;
}
.left-j > hr {
	position: absolute;
	width: 100%;
	border: 2px solid var(--orange);
	border-radius: 20%;
	margin-top: -1rem;
}
.left-j > div {
	display: flex;
	gap: 1rem;
}

.right-j {
	display: flex;
	justify-content: center;
	align-items: center;
}
.email-container {
	display: flex;
	gap: 3rem;
	background-color: gray;
	padding: 1rem 2rem;
}
.email-container > input {
	background-color: transparent;
	border: none;
	outline: none;
	color: var(--lightgray);
}
::placeholder {
	color: black;
}
.btn-j {
	background-color: var(--orange);
}
.btn-j:hover {
	color: var(--darkGrey);
}

@media screen and (max-width: 768px) {
	.Join {
		flex-direction: column;
		gap: 1rem;
	}
	.left-j {
		font-size: x-large;
		flex-direction: column;
	}
	.right-j {
		padding: 2rem;
	}
	.email-container {
		gap: 0;
		justify-content: space-between;
	}
}
